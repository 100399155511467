import React, { useEffect, useState } from "react";
import { db } from "../firebase";
import { collection, getDocs, query, orderBy } from "firebase/firestore";
import TemplatePage from "./App";
import { Alert, Card, ListGroup, Badge, Accordion, Button, ProgressBar } from 'react-bootstrap';
import { Link } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import { set } from "react-datepicker/dist/date_utils";

const Schedule: React.FC = () => {
    const [connectionStatus, setConnectionStatus] = useState<string>("");
    const [docData, setDocData] = useState<any[]>([]);  // ドキュメントのデータを保存する状態
    const [planDocDate, setPlanDocDate] = useState<any[]>([]);

    const getQuerys = async (dbName: string, baseNames: string, subNames?: string) => {
        // baseNames のdesc(降順)で並び替えをして取得する
        const q = query(collection(db, dbName), orderBy(baseNames, "desc"));
        const querySnapshot = await getDocs(q);

        // ドキュメントデータを状態に保存
        const docs = await Promise.all(querySnapshot.docs.map(async (doc) => {
            const data = doc.data();

            let subCollectionDocs:any[];

            if(subNames !== undefined){
                // サブコレクションのデータを取得
                const subCollectionRef = collection(db, dbName, doc.id, subNames);
                const subCollectionSnapshot = await getDocs(subCollectionRef);
                subCollectionDocs = subCollectionSnapshot.docs.map((subDoc: any) => subDoc.data());
                return { ...data, subNames: subCollectionDocs };
            }

            return { ...data };
        }));

        return docs;
    }

    // Firebase接続確認とデータ取得
    useEffect(() => {
        const checkConnection = async () => {
            try {
                // Firestoreのコレクションの参照を取得（Scheduleコレクションを参照）、並び順をtimestampの降順にする
                const docs = await getQuerys("Schedule","timestamp","content");
                const docs_2 = await getQuerys("Blog","timestamp","plan");
                
                setDocData(docs);
                setPlanDocDate(docs_2);
                setConnectionStatus("接続完了");
            } catch (error) {
                console.error("Firebase接続エラー:", error);
                setConnectionStatus("接続失敗");
            }
        };

        checkConnection();
    }, []);

    return (
        <TemplatePage>
            <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="1" >
                    <Accordion.Header>
                        <h4 style={{color: "#dc3545"}}>※はじめて利用される方へ※</h4>
                    </Accordion.Header>
                    <Accordion.Body>
                        <span>当サイトはいかなる小説投稿サイト様とも直接的な関係を持たない、有志による非公式・非公認サイトです。</span>
                        <p>ご利用前は必ず<Link to='./schedule/services'>利用規約</Link>をお読み頂き、ご承諾の上でご利用ください。</p>

                        <p>また、サイトドメインを「txh-g.tank.jp」から「txh-g.com」に変更します。旧ドメインからアクセスされている方は、2025年1月1日以降は閲覧いただけませんのでご注意ください。</p>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
            <p />
            <h2>実装済み報告</h2><small>※過去2週間分</small>
            <Card>
                <Card.Body>
                    <dl>
                        <div>
                            {docData.length === 0 && <ProgressBar animated now={45} label={"・・・読み込み中・・・"} />}
                            {docData.length > 0 && docData.map((doc, index) => (
                                <div key={index}>
                                    {doc.state !== 2 && <>
                                    {/* <pre>{doc.title}</pre>  {/* ドキュメントデータを表示 */}
                                    {/** doc.state === 1 で新データを示すバッジを表示*/}
                                    <dt><h4>{doc.title} {doc.state === 1 ? <Badge bg="danger">New</Badge> : ""}</h4></dt>
                                    {!doc.subNames && <span>ない</span>}
                                    {doc.subNames && doc.subNames.length > 0 && (
                                        <div>
                                            {doc.subNames.map((subDoc: any, subIndex: number) => (
                                                <div key={subIndex}>
                                                    <dd>
                                                        {subDoc.state !== 0 && <><b style={{ color: subDoc.category === "NEWS" ? "#dc3545" : "#0d6efd" }}>{subDoc.category}</b> - {subDoc.text}</>}
                                                        {subDoc.state === 0 && <s><b style={{ color: subDoc.category === "NEWS" ? "#dc3545" : "#0d6efd" }}>{subDoc.category}</b> - {subDoc.text}</s>}
                                                    </dd>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                    {/** doc.state = 0 (subCollectionなし) の場合、docの内容を表示する */}
                                    {doc.subNames.length ===0 && (
                                        <dd>
                                            <b style={{ color: doc.category === "NEWS" ? "#dc3545" : "#0d6efd" }}>{doc.category}</b> - {doc.text}
                                        </dd>
                                    )}
                                    <hr />
                                    </>}
                                </div>
                            ))}
                        </div>
                    </dl>
                    <span>過去の履歴は<Link to='./schedule/blog'>BLOG</Link>をご覧ください。</span>
                </Card.Body>
            </Card>

            <br />

            <h2>実装予定</h2>
            <ListGroup>
            {planDocDate.length === 0 && <ProgressBar animated now={65} label={"・・・読み込み中・・・"} />}
            {planDocDate.length > 0 && planDocDate.map((doc, index) => (
                                <div key={index}>
                                    {doc.subNames && doc.subNames.length > 0 && (
                                        <div>
                                            {doc.subNames.map((subDoc: any, subIndex: number) => (
                                                <div key={subIndex}>
                                                    {/** subDoc.state = 1 で実装完了 / subDoc.state = 0 で実装予定 */}
                                                    <ListGroup.Item>{subDoc.state === 1 && <><s>{subDoc.category} - {subDoc.text}</s> <b style={{ color: "#dc3545" }}>完了</b></>}
                                                    {subDoc.state === 0 && <><span>{subDoc.category} - {subDoc.text}</span> <b style={{ color: "green" }}>{subDoc.planDay}</b></>}</ListGroup.Item>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            ))}
                <ListGroup.Item></ListGroup.Item>
                <ListGroup.Item>その他検討中</ListGroup.Item>
                <ListGroup.Item></ListGroup.Item>
            </ListGroup>
        </TemplatePage>
    );
}

export default Schedule;
