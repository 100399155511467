import React, { useState } from "react";

// datetime format
const format = (value: string): string => {
    let resFormat : string;
    switch (value) {
        case "1":
            resFormat = "YYYY/MM/dd HH:mm:ss";
            break;
        case "2":
            resFormat = "YYYY年MM月dd日 HH時mm分ss秒";
            break;
        case "3":
            resFormat = "YYYY/MM/dd --";
            break;
        default:
            resFormat = "YYYY/MM/dd HH:mm:ss"; // デフォルトのメッセージやフォーマット
            break;
    }
    return resFormat;
}

// フォーマット適用用のヘルパー関数
const applyFormat = (date: Date, pattern: string): string => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    return pattern
        .replace("YYYY", year.toString())
        .replace("MM", month)
        .replace("dd", day)
        .replace("HH", hours)
        .replace("mm", minutes)
        .replace("ss", seconds);
};

// 使用調整のためvalue = 2関連の処理は一旦停止
const timePicEdit = (timePic: Date, oldTime: Date, currentTime: Date) => {
    // old_timePicと現在時刻の差をミリ秒で計算
    const timeDifference = (currentTime.getTime() - oldTime.getTime()) / 1000;
    
    // timePicにその差を加算
    return new Date(timePic.getTime() + timeDifference);
}

// time
export const timeFormat = (value: string, timePic: Date, timeRender: number, formatType: string, isList: boolean, old_timePic?: Date): { formattedDate: string, newOldTimePic: Date } => {
    let baseTime: Date;
    let formatPattern : string;
    let oldTime = old_timePic !== undefined ? old_timePic : new Date();

    // レス内容が格納されているListに関する操作
    if(isList){
        // isListフラグがtrueの場合、返される値はresList配列における「res_datetime_b」(base)に格納される
        // 「res_datetime_b」は格納以降改変不可　つまり以下の処理は初回の格納1回切りということになる
        switch (Number(value)) {
            case 1:
                baseTime = new Date(); // 現在時刻を取得
                break;

            // 使用調整のためvalue = 2関連の処理は一旦停止
            case 2:
                if (timePic !== null) {
                    // 現在の時刻を取得
                    const currentTime = new Date();
        
                    // timePicにその差を加算
                    baseTime = timePicEdit(timePic, oldTime, currentTime);
        
                    // old_timePicを更新
                    old_timePic = currentTime;
                } else {
                    baseTime = new Date();
                }
                break;
    
            case 3:
                baseTime = timePic; // timePicの時間を取得
    
                // timeRender分の幅でランダムに時を進める
                const randomSeconds = Math.floor(Math.random() * timeRender) + 1; // 1秒からtimeRender秒の間でランダムな値を取得
                const totalSecondsToAdd = timeRender + randomSeconds; // timeRender秒とランダムな秒数を加算
                baseTime = new Date(baseTime.getTime() + totalSecondsToAdd * 1000); // 合計秒数をミリ秒に変換して時間を進める
                break;
    
            default:
                baseTime = new Date();
        }

        // 「res_datetime_b」は画面に表示される日時情報のベース(オリジナル)となるものなので、必ず「YYYY/MM/DD hh:mm:ss」になるようにする
        formatPattern = format("1");

    }else{
        // フォーマットの変更がある度にこちらの処理が実行される
        // isList = trueは配列操作の初回のみ実行されるが、isList = falseとなる今回はフォーマットの選択が変わる度に実行され「res_datetime_v」(view)として格納される
        switch (Number(value)) {
            case 1:
                baseTime = timePic !== null ? timePic : new Date(); // 現在時刻を取得
                break;
    
            // 使用調整のためvalue = 2関連の処理は一旦停止
            case 2:
                if (timePic !== null) {
                    // 現在の時刻を取得
                    const currentTime = new Date();
        
                    // timePicにその差を加算
                    baseTime = timePicEdit(timePic, oldTime, currentTime);
        
                    // old_timePicを更新
                    oldTime = currentTime;
                } else {
                    baseTime = new Date();
                }
                break;
    
            case 3:
                baseTime = timePic; // timePicの時間を取得
    
                // timeRender分の幅でランダムに時を進める
                const randomSeconds = Math.floor(Math.random() * timeRender) + 1; // 1秒からtimeRender秒の間でランダムな値を取得
                const totalSecondsToAdd = timeRender + randomSeconds; // timeRender秒とランダムな秒数を加算
                baseTime = new Date(baseTime.getTime() + totalSecondsToAdd * 1000); // 合計秒数をミリ秒に変換して時間を進める
                break;
    
            default:
                // case文で何故かすべてdefault処理されてしまう問題
                baseTime = timePic !== null ? timePic : new Date();
        }

        formatPattern = format(formatType);
    }

    const editDate = applyFormat(baseTime, formatPattern);

    return {formattedDate: editDate, newOldTimePic: oldTime};
};