import React, { useState, useEffect, useRef, useDebugValue } from 'react';
import TemplatePage from "./App";
import Tabs from "./Custom/Tabs";
import BaseModals from './Custom/BaseModals';
import { ResUnion } from './Custom/BBS/UnionMode';
import { Button, Form, Offcanvas, OverlayTrigger , Tooltip, TooltipProps, ListGroup } from 'react-bootstrap';
import CustomTextbox from "./Custom/CustomTextbox";
import CustomForm from "./Custom/BBS/CustomForm";
import CustomTitle from './Custom/BBS/CustomTitle';
import CustomRange from './Custom/BBS/CustomRange';
import CustomSelect from './Custom/BBS/CustomSelect';
import CustomPic from './Custom/BBS/CustomPic';
import Cookies from 'js-cookie';
import CustomButton from './Custom/CustomButton';
import { generateRandomString, RES_Union, RES_Union_H } from './Custom/BBS/Option'
import { timeFormat } from './Custom/TimeEdit';
import CustomResNum from './Custom/BBS/CustomResNum';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css"
import InputTime from 'react-datepicker/dist/input_time';
import InputGroupText from 'react-bootstrap/esm/InputGroupText';
//import { JSX } from 'react/jsx-runtime';

type RES_Content = {
    res_no: number; // レス番号
    res_name: string; // レス名
    res_id: string; // レスID
    res_text: string; // レス内容
    originalID?: string; // 操作用の固定ID
    res_datetime_v?: string; // 投稿時間の表現 フォーマットの影響を受けるもの
    res_datetime_b?: string; // 投稿時間の表現 フォーマット変更前のベースとなるもの
};

interface Option {
    value: string;
    label: string;
}

const getOptionsNameCookie = (): Option[] => {
    const nameListCookie = Cookies.get('nameList');
    if (nameListCookie) {
        const nameList = JSON.parse(decodeURIComponent(nameListCookie)) as string[];
        return nameList.map((name, index) => ({
            value: name,
            label: name
        }));
    }
    return [];
};

const getOptionsIdCookie = (): Option[] => {
    const idListCookie = Cookies.get('idList');
    if (idListCookie) {
        const idList = JSON.parse(decodeURIComponent(idListCookie)) as string[];
        return idList.map((id, index) => ({
            value: id,
            label: id
        }));
    }
    return [];
};

// Cookieに保存するためのユーティリティ関数
const setCookie = (name: string, value: string, days: number) => {
    Cookies.set(name, value, { expires: days });
}

// localStorageに保存するための関数
const saveResArrayToLocalStorage = (resArray: RES_Content[]) => {
    localStorage.setItem('resArray', JSON.stringify(resArray));
};

// localStorageから取得するための関数
const getResArrayFromLocalStorage = (): RES_Content[] => {
    const resArrayString = localStorage.getItem('resArray');
    if (resArrayString) {
        return JSON.parse(resArrayString) as RES_Content[];
    }
    return [];
};

const TestEdit = () => {
    const inputRef = useRef<HTMLTextAreaElement>(null);

    // 名前(レス名)関連のstatus
    const [options, setOptions] = useState<Option[]>([]);
    const [options_id, setOptions_id] = useState<Option[]>([]);
    const [seleted_id, setSelected_id] = useState<Option[]>([]);

    const [title, setTitle] = useState(`タイトルの編集は「設定」から`);
    const [text, setText] = useState('');
    const [repText, setRepText] = useState('');
    const [h_repText, h_setRepText] = useState('');
    const [F_size, setFsize] = useState(16);
    const [selectedOption, setSelectedOption] = useState('ななしのネット民');
    const [pickerCol, setPickerCol] = useState('#e0e0e0');
    const [chatId, setChatId] = useState(generateRandomString(10));
    const [resNo, setResNo] = useState(1);
    
    const repTextRef = useRef<HTMLDivElement>(null);
    const hRepTextRef = useRef<HTMLDivElement>(null);

    // IDを表示するかどうか
    const [switchState, setIsSwitchOn] = useState(true);
    
    // レス内容操作・保存用
    const [resList, setResList] = useState<RES_Content[]>([]);
    // レス内容編集用modal
    const [modalShow, setModalShow] = useState(false);
    // modal用に整えたResList
    const [modalResNo, setModalResNo] = useState(0);
    const [modalResName, setModalResName] = useState("");
    const [modalResId, setModalResId] = useState("");
    const [modalResText, setModalResText] = useState("");
    const [modalCalendar, setModalCalendar] = useState<Date>();
    const [originalID, setOriginalId] = useState("");
    
    // サイズ等の設定用フック
    const [viewSize, setViewSize] = useState('200');
    const [inputViewSize, setInputViewSize] = useState('180');
    const [previewShow, setPreviewShow] = useState(true);
    const [codeCopyOnOff, setCodeCopyOnOff] = useState(false);
    const [textCopy, setTextCopy] = useState("");
    
    // offcanbas用のフック
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    
    const [setting_show, setSetting_show] = useState(false);
    const setting_handleClose = () => setSetting_show(false);
    const setting_handleShow = () => setSetting_show(true);
    
    // RES用設定画面に関連したstatus
    // 今日の日付
    // timeFormat関数の戻り値を配列として受け取る 
    const Today = new Date(timeFormat("1", new Date(), 10, "1", true).formattedDate.replace("/", "-").replace("/", "-"));
    const [oldTimePic, setOldTimePic] = useState<Date>();
    // RESに日時情報を表示するかどうか
    const [switchDateTimeState, setDateTimeOn] = useState(false);
    // date timeのフォーマット
    const [selectedDateTimeFormat, setselectedDateTimeFormat] = useState("");
    // 固定
    const dateTimeFormat = [{label: "YYYY/MM/DD hh:mm:ss", value: "1"},{label: "YYYY年MM月DD日 hh時mm分ss秒", value: "2"},{label: "YYYY/MM/DD --", value: "3"}];
    // date timeの時間経過の設定
    const [selectedDateTimeSum, setselectedDateTimeSum] = useState("");
    // 固定 {label: "リアルタイム(指定)", value: "2"},
    const dateTimeSum = [{label: "リアルタイム", value: "1"},{label: "指定", value: "3"}];
    const [calendar, setCalendar] = useState<Date>(new Date); // タイムピッカーを使用して取得する
    // 時間間隔
    const [timeCheck, setTimeCheck] = useState(60);
    // 時間間隔 幅
    const [timeCheckRange, setTimeCheckRange] = useState(60);
    // 保存時にファイル名に含む物
    const [download_Title, setDownload_Title] = useState(true);
    const [download_time, setDownload_time] = useState(true);
    const [download_string, setDownload_string] = useState(false);
    // download_string がtrueの場合のみ使用 任意の文字列を格納する
    const [download_stringText, setDownload_stringText] = useState("");
    // 保存時のフォーマット
    const [downloadFormat, setDownloadFormat] = useState("")
    // 固定
    const [downloadFormat_time, setDownloadFormat_time] = useState<Option[]>([{label: "YYYY/MM/DD hh:mm:ss", value: "1"},{label: "YYYY年MM月DD日 hh時mm分ss秒", value: "2"},{label: "YYYY/MM/DD --", value: "set"}]);
    
    // レス内容入力した際のhandle
    const handleTextChange = (value: string) => {
        setText(value);
    };
    
    //　スレタイトル入力した際のhandle
    const handleTitleChange = (value: string) => {
        setTitle(value);
        const newResArray = newResArrayAdd(1);
        submitUnion(value, newResArray, switchState);
        date_Save();
    };

    // レス用リストの内容が更新された時
    // IDを削除する関数
    const handleRemoveId = (originalId: string) => {
        const userConfirmed = window.confirm('レスを削除すると復元できません。\nCookieも削除されますがよろしいですか？');
        if (userConfirmed) {
            const newResArray = resList.filter(res => res.originalID !== originalId);

        // ステートを更新
        submitUnion(title, newResArray, switchState);
        setResList(newResArray);

        // localStorageに保存
        saveResArrayToLocalStorage(newResArray);
        }else{
            alert("処理をキャンセルしました。");
        }
    };

    // モーダルを開く際にデータをセットする関数
    const handleOpenModal = (date: RES_Content[], originalId: string) => {
        setOriginalId(originalId);
        const modalValue = date.find(res => res.originalID === originalId);

        setModalResNo(modalValue?.res_no ?? 0);
        setModalResName(modalValue?.res_name ?? "");
        setModalResId(modalValue?.res_id ?? "");
        setModalResText(modalValue?.res_text ?? "");
        let times = modalValue?.res_datetime_b ?? "";
        setModalCalendar(new Date(times.replace("/", "-").replace("/", "-")) ?? new Date);

        setModalShow(true);            // モーダルを開く
    };

    const Modal_handlResNo = (value:string) => {
        let newValu = value;
        setModalResNo(Number(newValu));
    }

    const Modal_handlResName = (value:string) => {
        let newValu = value;
        setModalResName(newValu);
    }

    const Modal_handlResId = (value:string) => {
        let newValu = value;
        setModalResId(newValu);
    }

    const Modal_handlResText = (value:string) => {
        let newValu = value;
        setModalResText(newValu);
    }

    const Modal_handleCalendar = (value: Date | null) => {
        let date = value || calendar;
        setModalCalendar(date);
    }

    // ※重要
    const handlCloseModal = () => {
        console.log("ID:"+originalID)
        let newDateTime_b = timeFormat(selectedDateTimeSum, modalCalendar ?? new Date(), timeCheckRange, selectedDateTimeFormat, true, oldTimePic).formattedDate;
        let newDateTime_v = timeFormat(selectedDateTimeSum, modalCalendar ?? new Date(), timeCheckRange, selectedDateTimeFormat, false,oldTimePic).formattedDate;
        let newDate = resList.map(res =>
            res.originalID === originalID
                ? { ...res,
                    res_no:     modalResNo  , // originalIDが一致する場合、res_noを更新
                    res_name:   modalResName,   // res_nameを更新
                    res_id:     modalResId  , // res_idを更新
                    res_text:   modalResText,
                    res_datetime_b: newDateTime_b,
                    res_datetime_v: newDateTime_v,}
                : res // 一致しない場合、そのまま返す
        );

        // localStorageに保存
        saveResArrayToLocalStorage(newDate);
    
        // ステートが更新された後にsubmitUnionを呼び出す
        submitUnion(title, newDate, switchState);
        modalReset();
    }

    const modalReset = () => {
        setOriginalId("");
        setModalResNo(0);
        setModalResName("");
        setModalResId("");
        setModalResText("");
        setModalShow(false)
    }

    const handleRangeChange = (value: number) => {
        setFsize(value);
        const newResArray = newResArrayAdd(1);
        submitUnion(title, newResArray, switchState);
    };

    const handlePicChange = (value: string) => {
        setPickerCol(value);
    }

    const handleSelectChange = (value: string) => {
        console.log("セレクト：",value)
        setSelectedOption(value);
    };

    const handleIdsChange = (value: string) => {
        setChatId(value);
    };

    const handleResNoChange = (value: string) => {
        setResNo(parseInt(value))
    }

    // テキストのみを保存する際、IDありなしの切り替え時に反映されないためもう少し精査する必要がある
    const textCopyUnion = (base: RES_Content[], idChecks: boolean, timeSwitch?: boolean) => {
        const timeOnOff = timeSwitch !== undefined ? timeSwitch : false;

        let copyItem = ``;

        if(idChecks){
            
            for(let t of base){
                copyItem += `${t.res_no}：${t.res_name} ${timeOnOff && `${t.res_datetime_v} `}ID:${t.res_id}
${t.res_text}
 
`
            }
        }else{
            for(let t of base){
                copyItem += `${t.res_no}：${t.res_name} ${timeOnOff && `${t.res_datetime_v} `}
${t.res_text}
 
`
            }
        }

        return copyItem;
    }

    // 投稿に関する処理一覧
    const submitBase = () => {
        const newResArray = newResArrayAdd();
        submitUnion(title, newResArray, switchState);

        // テキスト用のコピーを取る
        let copyItem = textCopyUnion(newResArray, switchState);

        // localStorageに保存
        saveResArrayToLocalStorage(newResArray);

        let resNewNo = resNo + 1;

        setCookie('resTitle', encodeURIComponent(title), 30);
        setCookie('resNewNo', encodeURIComponent(resNewNo.toString()), 30);

        setCookie('textCopyItem', encodeURIComponent(copyItem), 30);
        setCookie('codeCopyState', encodeURIComponent(codeCopyOnOff.toString()), 30);

        setResNo(resNo + 1);
        let random_Id = generateRandomString(10);
        setChatId(random_Id);
        setSelected_id([{ value: random_Id, label: random_Id }, ...options_id])
        setText("");
    }

    // ※重要 日情報関連のlocalStorage保存処理
    const date_Save = () => {

        // 保存時にファイル名に含む物
        localStorage.setItem("downloadTitle", JSON.stringify(download_Title));
        localStorage.setItem("downloadTime", JSON.stringify(download_time));
        localStorage.setItem("downloadString", JSON.stringify(download_string));

        // console.log("状態が保存されました");
    }

    const date_Input = () => {
        const savedDateTimeOn = localStorage.getItem("dateTimeOn");
        if (savedDateTimeOn !== null) {
            setDateTimeOn(JSON.parse(savedDateTimeOn));
        }

        const savedDateTimeFormat = localStorage.getItem("selectedDateTimeFormat");
        if (savedDateTimeFormat !== null) {
            setselectedDateTimeFormat(savedDateTimeFormat);
        }

        const savedDateTimeSum = localStorage.getItem("selectedDateTimeSum");
        // console.log("復元",selectedDateTimeSum);
        if (savedDateTimeSum !== null) {
            setselectedDateTimeSum(savedDateTimeSum);
        }

        const savedCalendar = localStorage.getItem("calendar");
        if (savedCalendar !== null) {
            setCalendar(new Date(savedCalendar));
        }

        const savedTimeCheckRange = localStorage.getItem("timeCheckRange");
        if (savedTimeCheckRange !== null) {
            setTimeCheckRange(Number(savedTimeCheckRange));
        }

        const savedDownloadTitle = localStorage.getItem("downloadTitle");
        if (savedDownloadTitle !== null) {
            setDownload_Title(JSON.parse(savedDownloadTitle));
        }

        const savedDownloadTime = localStorage.getItem("downloadTime");
        if (savedDownloadTime !== null) {
            setDownload_time(JSON.parse(savedDownloadTime));
        }

        const savedDownloadString = localStorage.getItem("downloadString");
        if (savedDownloadString !== null) {
            setDownload_string(JSON.parse(savedDownloadString));
        }

        // console.log("状態が復元されました");
    };

    // ※重要
    const submitUnion = (titles: string, resArray: RES_Content[], idCheck: boolean) => {
        setResList(resArray);
        let fontSize = F_size + "px";
        let allText = RES_Union(titles, pickerCol, resArray, fontSize, idCheck);
        let allCode = RES_Union_H(titles, pickerCol, resArray, idCheck, switchDateTimeState);
        // テキストコピー用の処理
        let textCopy = textCopyUnion(resArray, idCheck, switchDateTimeState);
        setRepText(allText);
        h_setRepText(allCode);
        setTextCopy(textCopy);
    }

    // 新規のレスを追加 ※重要
    const newResArrayAdd = (check: number = 0) => {
        // localStorageから既存のresArrayを取得
        const existingResArray = getResArrayFromLocalStorage();

        // レス操作時にキーとなるオリジナルIDを発行
        let original = generateRandomString(12);

        
        // 新しいレスを既存のresArrayに追加
        let newResArray = existingResArray;

        console.log("listの内容",newResArray);

        let addTime_b : string; // 日時情報におけるフォーマットを適応されるベース(オリジナル)となる日時
        let addTime_v : string; // addTime_b を元にフォーマットを適応させて加工したもの

        // 日時情報を出力するかどうか
        if(switchDateTimeState){
            // addTime_b を更新するのは新しいレスを生成する時の1回のみ(または、編集モードで日時を変更した時のみ)
            //console.log(`selectedDateTimeSum: ${selectedDateTimeSum}, calendar: ${calendar}, timeCheckRange: ${timeCheckRange}, selectedDateTimeFormat: ${selectedDateTimeFormat}`);
            const originalDate = timeFormat(selectedDateTimeSum, calendar, timeCheckRange, selectedDateTimeFormat, true, oldTimePic);
            addTime_b = originalDate.formattedDate;
            addTime_v = timeFormat(selectedDateTimeSum, new Date(originalDate.formattedDate.replace("/", "-").replace("/", "-")), timeCheckRange, selectedDateTimeFormat, false,oldTimePic).formattedDate;
            //console.log(addTime_b, addTime_v);
            // リアルタイム(指定/ value = 2)を選択している場合、前回投稿日時と比較するための値として保持
            //setOldTimePic(originalDate.newOldTimePic);
            if (check === 0) {
                newResArray = [...existingResArray, { res_no: resNo, res_name: selectedOption, res_id: chatId, res_text: text, originalID: original, res_datetime_v: addTime_v, res_datetime_b: addTime_b }];
                // この新しいレス以降のベースとなる日時を、このレスの日時に更新する
                setCalendar(new Date(addTime_b));
            }
        }else{
            if (check === 0) {
                // 日時情報を出力しない場合でも、後から日時情報を出力するよう変更した場合に対応できるようにする
                // 格納されるものは常にリアルタイム、フォーマットは1に固定
                addTime_b = timeFormat("1", new Date(), timeCheckRange, "1", true).formattedDate;
                newResArray = [...existingResArray, { res_no: resNo, res_name: selectedOption, res_id: chatId, res_text: text, originalID: original, res_datetime_v: addTime_b, res_datetime_b: addTime_b}];
            }
        }

        return newResArray;
    }

    // 投稿処理
    const onClick = () => {
        submitBase();
    };

    const copyToClipboard = (text: string) => {
        navigator.clipboard.writeText(text)
            .then(() => {
                alert("コピーしました。");
            })
            .catch(err => {
                alert("Failed to copy text to clipboard: 詳細はコンソールログを確認してください。");
                console.error('Failed to copy text to clipboard:', err);
                // エラーハンドリングを行う場合はここで適切に処理を追加します
            });
    };

    // CODE or TEXT コピー
    const onClick_Copy = () => {
        if(codeCopyOnOff){
            copyToClipboard(textCopy);
        }else{
            copyToClipboard(h_repText);
        }
    }

    const handleCodeCopyOnOffSwitchChange = () => {
        setCodeCopyOnOff(!codeCopyOnOff);
    };

    
    const onClick_Reset = () => {
        const userConfirmed = window.confirm('リセットすると復元できません。\nCookieや設定も削除されますがよろしいですか？');
        if (userConfirmed) {
            // ユーザーがOKをクリックした場合の処理
            let titles = title;

            setResNo(1);
            let random_Id = generateRandomString(10);
            setChatId(random_Id);
            setSelected_id([{ value: random_Id, label: random_Id }, ...options_id])
            setText("");

            setRepText(``);
            h_setRepText(``);
            setTextCopy(``);
            setResList([]);

            //date_Reset();
            setCalendar(new Date());
            localStorage.removeItem("calendar");

            // localStorageのリセット
            localStorage.removeItem('resArray');
            
            console.log(`タイトル：${titles} はリセットされました。`);
            // ここにリセット処理を記述します
        } else {
            // ユーザーがキャンセルをクリックした場合の処理
            alert(`リセットがキャンセルされました。`);
        }
    };

    // ※重要 日時情報関連のリセット処理
    const date_Reset = () => {
        // 日時情報表示するか否か
        setDateTimeOn(false);
        localStorage.removeItem("dateTimeOn");

        // date timeのフォーマット
        setselectedDateTimeFormat("");
        localStorage.removeItem("selectedDateTimeFormat");

        // date timeの時間経過の設定
        setselectedDateTimeSum("");
        localStorage.removeItem("selectedDateTimeSum");

        // タイムピッカーを使用して取得する
        setCalendar(new Date());
        localStorage.removeItem("calendar");

        // 時間間隔 幅
        setTimeCheckRange(10);
        localStorage.removeItem("timeCheckRange");

        // 保存時にファイル名に含む物
        setDownload_Title(true);
        localStorage.removeItem("downloadTitle");

        setDownload_time(true);
        localStorage.removeItem("downloadTime");

        setDownload_string(false);
        localStorage.removeItem("downloadString");

        console.log("状態がリセットされ、localStorage がクリアされました");
    }


    // ここから先はdate time関連
    const handleDateTimeOnOffChange = () => {
        setDateTimeOn(!switchDateTimeState);
        // 日時情報表示するか否か
        localStorage.setItem("dateTimeOn", JSON.stringify(!switchDateTimeState));
    };
    const handleDownload_TitleOnOffChange = () => {
        setDownload_Title(!download_Title);
        date_Save();
    };
    const handleDownload_TimeOnOffChange = () => {
        setDownload_time(!switchDateTimeState);
        date_Save();
    };
    const handleDownload_StringOnOffChange = () => {
        setDownload_string(!download_string);
        date_Save();
    };

    const handleDateTime_Renge = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseInt(event.target.value, 10);
        setTimeCheckRange(value);
        // 時間間隔 幅
        localStorage.setItem("timeCheckRange", value.toString());
    };
    

    const formatParse = (times: string, value: string) => {
        const formattedDate = times.replace("/", "-").replace("/", "-");
        const date = new Date(formattedDate);
    
        const formats = timeFormat(selectedDateTimeSum, date, timeCheckRange, value, false);
    
        return formats;
    }

    // ※重要
    const handleSelect_dataFormat = (value: string) => {
        setselectedDateTimeFormat(value);
    
        const resArray = resList;
    
        const newRes = resArray.map(res => ({
            ...res,  // 現在のレスデータをそのまま引き継ぎ
            res_datetime_v: res.res_datetime_b !== undefined ? formatParse(res.res_datetime_b, value).formattedDate : res.res_datetime_v
        }));
    
        // 更新したレスデータを状態にセット
        submitUnion(title, newRes, switchState);
        setResList(newRes);
        // localStorageに保存
        saveResArrayToLocalStorage(newRes);
        // date timeのフォーマット
        localStorage.setItem("selectedDateTimeFormat", value);
    };
    

    const handleSelect_timeType = (value: string) => {
        console.log(value);
        setselectedDateTimeSum(value);
        localStorage.setItem("selectedDateTimeSum", value);
    }

    const handleSetCalender = (value: Date | null) => {
        let date = value || Today;
        setCalendar(date);
        // タイムピッカーを使用して取得する
        localStorage.setItem("calendar", date?.toISOString() || '');
    }

    // セレクトボックスの操作
    const handleSelect_DownloadFormatChange = (value: string) => {
        setDownloadFormat(value);
    };

    /** ここから先はキーイベントに関する処理 */

    const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
        // 例: Ctrl+Sが押されたときに何かをする
        if (event.ctrlKey && event.key === 's') {
            event.preventDefault();
            submitBase();
        }
    };
    /** ここまでがキーイベントに関する処理 */

    // 重要 足りない項目を埋める
    const fillMissingFields = (resArray: RES_Content[]) => {
        let datetimes = timeFormat("1", new Date(), 60, "1", true).formattedDate;

        return resArray.map(res => {
            if (!res.originalID) {
                res.originalID = generateRandomString(12);
            }
            if (!res.res_datetime_v) {
                res.res_datetime_v = datetimes;
            }
            if (!res.res_datetime_b) {
                res.res_datetime_b = datetimes;
            }
            return res;
        });
    };

    useEffect(() => {
        if (repTextRef.current) {
            repTextRef.current.scrollTop = repTextRef.current.scrollHeight;
        }
    }, [repText]);
    
    useEffect(() => {
        if (hRepTextRef.current) {
            hRepTextRef.current.scrollTop = hRepTextRef.current.scrollHeight;
        }
    }, [h_repText]);
    
    useEffect(() => {
        
    },[F_size])

    // localStorageに保存した情報を取得し表示する処理
    useEffect(() => {
        // date関連
        date_Input();

        // ID 表示するかしないかのswitch
        let idChecks = true;
        const savedSwitchState = Cookies.get('switchState');
        if (savedSwitchState !== undefined) {
            idChecks = (decodeURIComponent(savedSwitchState) === `true`);
            setIsSwitchOn(idChecks);
        }

        /// const savedCodeCopyState = Cookies.get('codeCopyState');
        /// if (savedCodeCopyState !== undefined) {
        ///     setIsSwitchOn(decodeURIComponent(savedCodeCopyState) === 'true');
        /// }

        const savedTextCopyItem = Cookies.get('textCopyItem');
        if(savedTextCopyItem !== undefined){
            setTextCopy(decodeURIComponent(savedTextCopyItem));
        }

        const storedResTitle = Cookies.get('resTitle');
        const storedResNewNo = Cookies.get('resNewNo');

        const storedResArray = getResArrayFromLocalStorage();
        const filledResArray = fillMissingFields(storedResArray);
        // 保存した設定情報に基づいて、ストレージ内にテキストがあれば整形して表示する処理
        submitUnion(title, filledResArray, idChecks);

        const optionsNameCookie = getOptionsNameCookie();
        setOptions(optionsNameCookie);
        setSelectedOption(optionsNameCookie[0].value);

        const optionsIdCookie = getOptionsIdCookie();
        setOptions_id(optionsIdCookie);
        setSelected_id([{ value: chatId, label: chatId }, ...optionsIdCookie]);

        if (storedResTitle) {
            let titles = decodeURIComponent(storedResTitle);
            setTitle(titles);
        }
        if (storedResNewNo) {
            let newNo = decodeURIComponent(storedResNewNo);
            setResNo(Number(newNo));
        }

        const storedViewSize = Cookies.get('viewSize');
        const storedInputViewSize = Cookies.get('InputViewSize');
        const storedPreviewShowState = Cookies.get('previewShowState');

        if (storedViewSize) {
            let size = decodeURIComponent(storedViewSize);
            setViewSize(size);
        } else {
            let baseSize = "200";
            setViewSize(baseSize);
        }
        if (storedInputViewSize) {
            let inputSize = decodeURIComponent(storedInputViewSize);
            setInputViewSize(inputSize);
        } else {
            let baseSize = "180";
            setInputViewSize(baseSize);
        }
        if (storedPreviewShowState !== undefined) {
            setPreviewShow(decodeURIComponent(storedPreviewShowState) === 'true');
        } else {
            setPreviewShow(true);
        }


    }, []);

    useEffect(() => {
        inputRef.current?.focus(); // フォーカスを設定
    }, []);

    const TABS = [
        {
            id: "edit-text",
            title: "EDIT",
            content: <ResUnion title={title} picCol={pickerCol} resArray={resList} editModal={handleOpenModal} removeItem={handleRemoveId} size={F_size.toString() + "px"} switchs={switchState} height={viewSize + "px"} timeSwitchs={switchDateTimeState} ref={repTextRef} />
        },
        {
            id: 'h_text',
            title: 'CODE',
            content: <CustomTextbox height={viewSize + "px"} size={F_size + "px"} value={h_repText} />,
        },
    ];

    const ModifyModal = () => {
        return (
            <div>
                <div className='row'>
                    <p style={{fontSize: "12px"}}>※ 2024/10/27 以前のバージョンで作成されたレスには使用できません。</p>
                </div>
                <div className='row'>
                    <div className='col-2'>
                        <CustomResNum onChange={Modal_handlResNo} values={modalResNo.toString()} />
                    </div>
                    <div className='col-5'>
                        <CustomTitle height='30px' onChange={Modal_handlResName} value={modalResName} label='ここに名前を入力' />
                    </div>
                    <div className='col-5'>
                        <CustomTitle height='30px' onChange={Modal_handlResId} value={modalResId} label='ここにIDを入力' />
                    </div>
                </div>
                <div className='row'>
                    <div className='col-5'>
                        <DatePicker selected={modalCalendar} onChange={Modal_handleCalendar} dateFormat="yyyy/MM/dd HH:mm:ss" showTimeInput timeInputLabel="Time:" className="form-control" />
                    </div>
                </div>
                <p style={{fontSize: "14px", color: "#960000"}}>※ 日時情報表示機能が実装される前のレスに使用できない場合があります。</p>
                <div className='row'>
                    <CustomForm ref={inputRef} height={"100px"} onChange={Modal_handlResText} label=' text' value={modalResText} />
                </div>
            </div>
        )
    }

    return (
        <TemplatePage>
            
            <BaseModals
                show={modalShow}
                onHide={() => setModalShow(false)}
                title="レスの編集"
                footerContent={<Button onClick={() => handlCloseModal()}>完了</Button>}
            >
                {/* 任意のコンテンツをchildrenとして渡す */}
                {ModifyModal()}
            </BaseModals>


            <CustomButton size="sm" variant="primary" onClick={handleShow}>
            <i className="bi bi-box-arrow-in-up-left"></i> プレビュー
            </CustomButton>
            <span> </span>
            <CustomButton size="sm" variant="outline-primary" onClick={setting_handleShow}>
                <i className="bi bi-tools"></i> 設定
            </CustomButton>

            <Offcanvas show={show} onHide={handleClose}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>特殊タグのプレビュー画面</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                <ResUnion title={title} picCol={pickerCol} resArray={resList} editModal={handleOpenModal} removeItem={handleRemoveId} size={F_size.toString() + "px"} switchs={switchState} height={"0px"} timeSwitchs={switchDateTimeState}></ResUnion>
                </Offcanvas.Body>
            </Offcanvas>

            <Offcanvas show={setting_show} onHide={setting_handleClose}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>RES用設定画面</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <div>
                        <span>RES用にのみ反映されます。</span>
                        <p style={{ fontSize: "12px" }}>※「Id」と「名前」は「Setting」タブで編集可能です。</p>
                        <p>◆ タイトル</p>
                        <CustomTitle height='30px' onChange={handleTitleChange} value={title} label='ここにタイトル' />
                        <br />
                        <hr />
                        <p>◆ 日時情報</p>
                        <div className='d-flex align-items-center'>
                            <Form>
                                <Form.Check // prettier-ignore
                                    type="switch"
                                    id="custom-switch-datetime"
                                    label={<span style={{ fontSize: "16px" }}>スレ内に表示する</span>}
                                    style={{ fontSize: "16px" }}
                                    checked={switchDateTimeState}
                                    onChange={handleDateTimeOnOffChange}
                                />
                            </Form>
                        </div>
                        <p />
                        {switchDateTimeState && (
                            <>
                                <span>日時のフォーマット</span>
                                <CustomSelect options={dateTimeFormat} selectedValue={selectedDateTimeFormat} onChange={handleSelect_dataFormat} />
                                <span>時間経過</span>
                                <CustomSelect options={dateTimeSum} selectedValue={selectedDateTimeSum} onChange={handleSelect_timeType} />
                                {selectedDateTimeSum === "3" && (
                                    <>
                                        <div>スレの開始日時と、投稿間隔を指定してください</div>
                                        <DatePicker selected={calendar} onChange={handleSetCalender} dateFormat="yyyy/MM/dd HH:mm:ss" showTimeInput timeInputLabel="Time:" className="form-control" />
                                        <p/>
                                        <div className="d-flex align-items-center">
                                            <Form.Control
                                                as="textarea"
                                                style={{ height: "30px", width: "60px", fontSize: "16px" }}
                                                onChange={handleDateTime_Renge}
                                                value={timeCheckRange}
                                            />
                                            <span style={{ marginLeft: "6px" }}>/ 秒内間隔</span>
                                        </div>
                                        <div style={{ fontSize: "14px" }}>※「60」と入力した場合、60秒+60秒以内のランダムな秒数を加算し「投稿時間」として生成します。</div>
                                        <div style={{ fontSize: "14px" }}>例：開始時間 2024/11/23 10:00:00</div>
                                        <div style={{ fontSize: "14px" }}>2024/11/23 10:01:00～10:02:00の範囲で生成</div>

                                    </>
                                )}
                            </>
                        )}
                        <br />
                        <hr />
                        <span style={{color: "red"}}>※ 調整中のため使用できません ※</span>
                        <p>◆ 保存先情報</p>
                        <p>ファイル名に含める情報</p>
                        <div className='d-flex align-items-center'>
                            <Form>
                                <Form.Check // prettier-ignore
                                    type="switch"
                                    id="custom-switch-title"
                                    label={<span style={{ fontSize: "16px" }}>スレタイトル</span>}
                                    style={{ fontSize: "16px" }}
                                    checked={download_Title}
                                    disabled={true}
                                    onChange={handleDownload_TitleOnOffChange}
                                />
                                <Form.Check // prettier-ignore
                                    type="switch"
                                    id="custom-switch-date"
                                    label={<span style={{ fontSize: "16px" }}>保存日時</span>}
                                    style={{ fontSize: "16px" }}
                                    checked={download_time}
                                    disabled={true}
                                    onChange={handleDownload_TimeOnOffChange}
                                />
                                <Form.Check // prettier-ignore
                                    type="switch"
                                    id="custom-switch-selectText"
                                    label={<span style={{ fontSize: "16px" }}>任意の文字列</span>}
                                    style={{ fontSize: "16px" }}
                                    checked={download_string}
                                    disabled={true}
                                    onChange={handleDownload_StringOnOffChange}
                                />
                            </Form>
                        </div>
                        <p />
                        <span>日時のフォーマット</span>
                        <CustomSelect disabled={true} options={downloadFormat_time} onChange={handleSelect_DownloadFormatChange} />
                        <span>ファイル名のプレビュー</span>
                        <p>{title}_</p>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>

            {!previewShow && <p style={{ color: "red", fontSize: "14px" }}>※プレビュー画面の固定化：無効<span style={{color:"black", fontSize: "14px"}}> / フォント： {F_size} px</span></p>}
            {previewShow && <p style={{ color: "green", fontSize: "14px" }}>※プレビュー画面の固定化：有効<span style={{color:"black", fontSize: "14px"}}> / フォント： {F_size} px</span></p>}
            {previewShow && <Tabs tabs={TABS} />}
            {previewShow && <br />}
            <div className='d-flex align-items-center'>
            <Form>
                <Form.Check // prettier-ignore
                    type="switch"
                    id="custom-switch-text"
                    label={<span style={{fontSize: "12px"}}>CODEではなくテキストをコピーする</span>}
                    style={{fontSize: "12px"}}
                    checked={codeCopyOnOff}
                    onChange={handleCodeCopyOnOffSwitchChange}
                />
            </Form>
            </div>

            <p />
            <div className="row">
                <div className="col">
                    <CustomResNum onChange={handleResNoChange} values={resNo.toString()} />
                </div>
                <div className="col">
                    <CustomSelect options={options} onChange={handleSelectChange} />
                </div>
                <div className="col">
                    <CustomSelect options={seleted_id} selectedValue={chatId} onChange={handleIdsChange} />
                </div>
                <div className='col'>
                    <CustomPic onChange={handlePicChange} />
                </div>
            </div>
            <CustomRange size={F_size} onRangeChange={handleRangeChange} />
            <CustomForm height={inputViewSize + "px"} onChange={handleTextChange} onKeyDown={handleKeyDown} value={text} />
            <span style={{fontSize: "12px"}}>※ PC版では「Ctrl + S」で投稿処理可能です。</span>
            <div className='row'>
                <div className='col'>
                    <CustomButton variant="warning" width='100%' onClick={onClick_Copy}>コピー</CustomButton>
                </div>
                <div className='col'>
                <CustomButton width='100%' onClick={onClick}>投稿</CustomButton>
                </div>
                <div className='col'>
                    <CustomButton variant="danger" width='100%' onClick={onClick_Reset}>リセット</CustomButton>
                </div>
            </div>
        </TemplatePage>
    );
};

export default TestEdit;