import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Tags from './sub/Tags';
import Edit from './sub/Edit';
import Sample from './sub/Sample';
import Setting from './sub/Setting';
import TestHtml from './sub/TestHtml';
import TestApp from './sub/testApps';
import Schedule from './sub/Schedule';
import MaintenanceApp from './sub/Maintenance';
import BlogApp from './sub/BlogApp';
import Changer from './sub/Changer';
import Chat from './sub/Chat';
import SendBox from './sub/sendBox';
import Template from './sub/Template';
import Services from './sub/Service';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

//basename="/TxH-G/"

const Root: React.FC = () => {
  return (
    <React.StrictMode>
      {/**　リリース時に変更する
       * 特定フォルダからのみアクセスさせたい場合は以下の様に変更する
       *  <BrowserRouter basename="/TxH-G/"> 
       * サブアドレスから直接アクセスさせたい場合はbasenameは「/」で良い*/}
      <BrowserRouter basename="/">
        <Routes>
          <Route path="/"                  element={<Schedule />} />
          <Route path="/simple"            element={<Tags />} />
          <Route path="/edit"              element={<Edit />} />
          <Route path="/edit/res"          element={<Edit />} />
          <Route path="/edit/chat"         element={<Chat />} />
          <Route path="/edit/changer"      element={<Changer />} />
          <Route path="/setting"           element={<Setting />} />
          <Route path="/sample"            element={<Sample />} />
          <Route path="/schedule"          element={<Schedule />} />
          <Route path="/schedule/blog"     element={<BlogApp />} />
          <Route path="/schedule/services" element={<Services />} />
          <Route path="/bbs"               element={<SendBox />} />

          {/** メンテナンス時のみ以下を使用
          <Route path="/"         element={<MaintenanceApp />} />
          <Route path="/simple"   element={<MaintenanceApp />} />
          <Route path="/edit"     element={<MaintenanceApp />} />
          <Route path="/setting"  element={<MaintenanceApp />} />
          <Route path="/sample"   element={<MaintenanceApp />} />
          <Route path="/schedule" element={<MaintenanceApp />} />
           */}

          {/** テスト時のみ使用する↓ 
           * <Route path="/test" element={<Services />} />
          */}
        </Routes>
      </BrowserRouter>
    </React.StrictMode>
  );
};

ReactDOM.render(
  <Root />,
  document.getElementById('root')
);

reportWebVitals();
