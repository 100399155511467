import { useState, useEffect, useRef } from "react";
import TemplatePage from "./App";
import Tabs from "./Custom/Tabs";
import { Badge, Button, Card, CardBody, Offcanvas, ProgressBar } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Cookies from 'js-cookie';
import { db } from "../firebase";
import { collection, getDocs, query, orderBy, addDoc, runTransaction, doc } from "firebase/firestore";
import CustomForm from "./Custom/BBS/CustomForm";
import CustomButton from "./Custom/CustomButton";
import CustomSelect from "./Custom/BBS/CustomSelect";
import BaseModals from "./Custom/BaseModals";

// Cookieに保存するためのユーティリティ関数
const setCookie = (name: string, value: string, days: number) => {
    Cookies.set(name, value, { expires: days });
}

const dbName = "BBSdate";
const NG_words: string[] = ["バカ", "馬鹿", "アホ", "阿呆", "クソ", "糞", "死ね", "シネ", "殺す", "コロス", "詐欺", "スパム", "エロ", "ポルノ", "アダルト", "セックス", "エッチ", "チビ", "デブ", "ブス", "ハゲ", "障害者", "アスペ"];
const containsNGWord = (text: string) => {
    return NG_words.some(word => text.includes(word));
};

const SendBox = () => {
    // サイズ等の設定用フック
    const [viewSize, setViewSize] = useState('200');
    const [inputViewSize, setInputViewSize] = useState('180');
    const [previewShow, setPreviewShow] = useState(true);

    // offcanbas用のフック
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [modalShow, setModalShow] = useState(false);
    
    const [connectionStatus, setConnectionStatus] = useState<string>("");
    const [docData, setDocData] = useState<any[]>([]);  // ドキュメントのデータを保存する状態

    const [text, setText] = useState<string>("");
    const [error, setError] = useState<string | null>(null);
    const repTextRef = useRef<HTMLDivElement>(null);

    const selectItemBox = [{label: "要望", value: "0"},{label: "質問", value: "1"},{label: "その他", value: "2"}]
    const [item, setItem] = useState<number>(0);
    const getLabelByValue = (value: number) => { const selectedItem = selectItemBox.find(box => parseInt(box.value) === value); return selectedItem ? selectedItem.label : "要望"; };

    const handleTextChange = (value: string) => {
        setText(value);
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
        // 例: Ctrl+Sが押されたときに何かをする
        if (event.ctrlKey && event.key === 's') {
            event.preventDefault();
            //submitBase();
        }
    };


    const getQuerys = async (dbName: string, baseNames: string) => {
        // baseNames のdesc(降順)で並び替えをして取得する
        const q = query(collection(db, dbName), orderBy(baseNames, "asc"));
        const querySnapshot = await getDocs(q);

        // ドキュメントデータを状態に保存
        const docs = await Promise.all(querySnapshot.docs.map(async (doc) => {
            console.log("Document ID: ", doc.id);  // ドキュメントIDをコンソールに表示
            const data = doc.data();
            return { ...data };
        }));

        return docs;
    }

    const ModifyModal = () => {
        return (
            <div>
                <Button variant="warning" onClick={handleShow} >
                <i className="bi bi-exclamation-triangle-fill" /> 投稿する際の注意事項 <i className="bi bi-exclamation-triangle-fill" /></Button>
                {offcanvesBody()}
                <p />
                <span>Category：<u>{getLabelByValue(item)}</u></span>
                <p>{text}</p>
                {error && <div style={{ color: 'red' }}>{error}</div>}
            </div>
        )
    }

    const handleOpenModal = () => {
        if (containsNGWord(text)) {
            setError("投稿内容にNGワードが含まれています。修正してください。");
        } else if (text.trim() === "") {
            setError("投稿内容がありません。確認し、修正してください。");
        }else{
            setError(null);
        }

        setModalShow(!modalShow);
    }

    const handleSubmit = async () => {
        try {
            await runTransaction(db, async (transaction) => {
                const docRef = doc(collection(db, dbName));
                transaction.set(docRef, {
                    add_day: new Date(), // 現在のタイムスタンプを使用
                    type: item, // 例: 要望 ユーザーが指定出来る add初期は0 選択式
                    state: 0, // 例: 未確認 管理側が指定出来る add初期は0固定 3の場合は表示しない
                    message: text, // フォームから入力されたテキスト
                    res: "" // 初期値として空の文字列を設定
                });
                console.log("Transaction successfully committed!");
            });

            setModalShow(!modalShow);
            setText(''); // テキストフィールドをリセット
            window.location.reload(); // ページをリロード
        } catch (e) {
            console.error("Transaction failed: ", e);
        }
    };

    const handleSelectBox = (value: string) => {
        setItem(Number(value));
    }

    useEffect(() => {
        const storedViewSize = Cookies.get('viewSize');
        const storedInputViewSize = Cookies.get('InputViewSize');
        const storedPreviewShowState = Cookies.get('previewShowState');

        if (storedViewSize) {
            let size = decodeURIComponent(storedViewSize);
            setViewSize(size);
        } else {
            let baseSize = "200";
            setViewSize(baseSize);
        }
        if (storedInputViewSize) {
            let inputSize = decodeURIComponent(storedInputViewSize);
            setInputViewSize(inputSize);
        } else {
            let baseSize = "180";
            setInputViewSize(baseSize);
        }
        if (storedPreviewShowState !== undefined) {
            setPreviewShow(decodeURIComponent(storedPreviewShowState) === 'true');
        } else {
            setPreviewShow(true);
        }

        const checkConnection = async () => {
            try {
                // Firestoreのコレクションの参照を取得（Scheduleコレクションを参照）、並び順をtimestampの降順にする
                const docs = await getQuerys(dbName, "add_day");

                setDocData(docs);
                setConnectionStatus("接続完了");
            } catch (error) {
                console.error("Firebase接続エラー:", error);
                setConnectionStatus("接続失敗");
            }
        };

        checkConnection();
    }, []);

    useEffect(() => {
        if (repTextRef.current) {
            repTextRef.current.scrollTop = repTextRef.current.scrollHeight;
        }
    }, [docData]);

    const getCategory = (type: number) => { switch (type) { case 0: return "要望"; case 1: return "質問"; case 2: return "その他"; default: return "要望"; } };
    const getState = (type: number) => { switch (type) { case 0: return "未確認"; case 1: return "確認済"; case 2: return "調査中"; case 3: return "完了"; default: return "未確認"; } };
    const convertTimestampToDate = (timestamp: { seconds: number, nanoseconds: number }): string => {
        return new Date(timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000).toLocaleString();
    };

    const offcanvesBody = () => {
        return (
            <Offcanvas show={show} onHide={handleClose}>
                <Offcanvas.Header closeButton style={{ backgroundColor: "gray", color: "white" }}>
                    <Offcanvas.Title><i className="bi bi-exclamation-triangle" style={{color: "yellow"}} /> 投稿する際の注意事項 <i className="bi bi-exclamation-triangle" style={{color: "yellow"}} /></Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body style={{ backgroundColor: "gray", color: "white" }}>
                    <p>この機能は以下を目的としたものです。</p>
                    <ul>
                        <li>TxH-G(以下、当サイトと表記)利用者が管理者宛に匿名で要望、質問等のメッセージを送る</li>
                        <li>当サイト管理者が利用者の質問等に返信する</li>
                        <li>当サイトの改修等に関連した情報交換をする</li>
                    </ul>
                    <span>上記の目的以外での使用や、以下に該当する事項は固く禁止しております。</span>
                    <ul>
                        <li>個人情報の開示</li>
                        <li>他人のプライバシーを開示</li>
                        <li>晒し行為</li>
                        <li>誹謗中傷や暴言、煽り、差別となる内容</li>
                        <li>外部サイトのURLを貼る行為</li>
                        <li>公序良俗に反する、不適切なコンテンツの投稿</li>
                        <li>法令違反</li>
                        <li>スパム行為</li>
                    </ul>
                    <span>投稿された内容は<b><u>全ユーザーが閲覧可能</u></b>です。</span>
                    <p>また、1度投稿されると<b><u>ユーザー側からは削除できません</u></b>。</p>
                    <span>投稿前に必ず内容をご確認ください。</span>
                </Offcanvas.Body>
            </Offcanvas>
        )
    }

    return (
        <TemplatePage>
            <BaseModals
            show={modalShow}
            onHide={() => setModalShow(false)}
            title="投稿内容の確認"
            footerContent={<Button onClick={() => handleSubmit()}>投稿</Button>}>{ModifyModal()}</BaseModals>

            <Card ref={repTextRef} style={{ height: "350px", overflowY: 'scroll', whiteSpace: 'pre-wrap' }}>
            {docData.length === 0 && <CardBody><ProgressBar animated now={45} label={"・・・読み込み中・・・"} /></CardBody>}
                {docData.length > 0 && docData.map((doc, index) => (
                    <div key={index}>
                        {doc.state !== 3 && <>
                            <CardBody>
                                <Badge bg="primary">{getCategory(doc.type)}</Badge><span>｜</span>
                                <span>{convertTimestampToDate(doc.add_day)} 投稿</span>
                                <hr style={{ border: '3px dotted black', borderColor: 'green' }} />
                                <span>{doc.message}</span>
                                <p />
                                <hr style={{ border: '3px dotted black', borderColor: 'green' }} />
                                {doc.res.trim() !== "" && <><Card><CardBody style={{ backgroundColor: "lightgoldenrodyellow"}}>{doc.res}</CardBody></Card></>}
                                <p />
                                <span>状態：
                                    {doc.state === 0 && <Badge bg="secondary">{getState(doc.state)}</Badge>}
                                    {doc.state !== 0 && <Badge bg="danger">{getState(doc.state)}</Badge>}</span>
                            </CardBody>
                            <hr />
                        </>}
                    </div>
                ))}
            </Card>
            <p />
            <Button variant="warning" onClick={handleShow} >
                <i className="bi bi-exclamation-triangle-fill" /> 投稿する際の注意事項 <i className="bi bi-exclamation-triangle-fill" /></Button>
            {offcanvesBody()}
            <p />

            <CustomSelect options={selectItemBox} selectedValue={item.toString()} onChange={handleSelectBox} />

            {error && <div style={{ color: 'red' }}>{error}</div>}

            <CustomForm height={"100px"} onChange={handleTextChange} onKeyDown={handleKeyDown} value={text} />
            <div className="row">
                <div className="col">
                    <CustomButton width="100%" onClick={handleOpenModal}>投稿</CustomButton>
                </div>
            </div>
        </TemplatePage>
    );
}

export default SendBox;
