import React, { ReactNode } from "react";
import { Container, Navbar, Nav, NavDropdown } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link } from "react-router-dom"; // ルーターリンクを使う場合

interface TemplateProps {
    children: ReactNode;
}

const TestApp = (props: TemplateProps) => {
    return (
        <>
            <Navbar bg="dark" variant="dark">
                <Container>
                    <Navbar.Brand as={Link} to="/">TxH-G</Navbar.Brand>
                    <Nav className="me-auto">
                        {/**メンテナンスなどで機能を制限するときはdisabledを使用する */}
                        <Nav.Link as={Link} to="/simple">Simple</Nav.Link>
                        <NavDropdown title="Edit" id="basic-nav-dropdown">
                            <NavDropdown.Item as={Link} to="/edit/res">RES</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/edit/chat">CHAT</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/edit/changer">CHANGER</NavDropdown.Item>
                            {/**いつか使うかも知れないやつ
                            <NavDropdown.Divider />
                            <NavDropdown.Item as={Link} to="/separated">Separated link</NavDropdown.Item>
                             */}
                        </NavDropdown>
                        <Nav.Link as={Link} to="/setting">Setting</Nav.Link>
                        <Nav.Link as={Link} to="/sample">Sample</Nav.Link>
                        {/** テスト時のみ使用する↓ 
                         *<Nav.Link as={Link} to="/bbs">SendBox</Nav.Link>
                         * <Nav.Link as={Link} to="/edit">Edit</Nav.Link>
                         * <Nav.Link as={Link} to="/schedule">Schedule</Nav.Link>
                         * <Nav.Link as={Link} to="/test">Test</Nav.Link>
                        */}

                    </Nav>
                </Container>
            </Navbar>
            <Container
                style={{ minHeight: "calc(100vh - 56px - 56px)", paddingTop: "16px" }}
            >
                {props.children}
            </Container>
            <footer className="bg-dark text-light text-center py-3">
                © 2024 siotasio / support: X <Link target='_blank' to={'https://x.com/Siotasio'}>@Siotasio</Link> / <Link to="/bbs" className="text-light">BBS</Link>
            </footer>
        </>
    );
};

export default TestApp;
